import { defineStore } from 'pinia';
import { useAppStore as appStore } from '@/store';
import APIRequest from '@/libs/cloud_auth';
import axios from 'axios';
import dayjs from 'dayjs';

export const usePlayerStore = defineStore('players', {
  state: () => ({
    isLoading: false,
    sort: false,
    search: '',
    players: [],
    devices: [],
    availablePlayers: [],
    playersFilterList: [],
    count: 0,
    totalPages: 1,
    next: null,
    previous: null,
    controller: new AbortController(),
    error: '',
  }),
  getters: {
    isResultEmpty() {
      if (this.isLoading) return false;
      return this.players.length === 0;
    },
  },
  actions: {
      compareVersions(v1, v2) {
        const regex = /(\d+|[a-zA-Z]+)/g;
    
        const splitV1 = v1.match(regex).map(part => (isNaN(part) ? part : Number(part)));
        const splitV2 = v2.match(regex).map(part => (isNaN(part) ? part : Number(part)));
    
        for (let i = 0; i < Math.max(splitV1.length, splitV2.length); i++) {
            const part1 = splitV1[i] !== undefined ? splitV1[i] : (typeof splitV2[i] === 'number' ? 0 : '');
            const part2 = splitV2[i] !== undefined ? splitV2[i] : (typeof splitV1[i] === 'number' ? 0 : '');
    
            if (typeof part1 === 'number' && typeof part2 === 'number') {
                if (part1 > part2) return -1; // Descending order
                if (part1 < part2) return 1;
            } else if (typeof part1 === 'string' && typeof part2 === 'string') {
                if (part1 > part2) return 1;  // Default > RC
                if (part1 < part2) return -1;
            } else {
                return typeof part1 === 'number' ? -1 : 1;
            }
        }
        return 0;
    },
    async getSTBInfo(macAddress) {
      return new Promise((resolve, reject) => {
        APIRequest('websocket/stbinfo/?mac=' + macAddress
        , 'GET').then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      })
    },
    async remoteControl(macAddress) {
      return new Promise((resolve, reject) => {
        APIRequest('gadgets/vnc_session/remote_control/'
        , 'post', {
          mac: [macAddress]
        }).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      })
    },
    async wifiTest(mac) {
      return new Promise((resolve, reject) => {
        APIRequest('gadgets/device/wifi_test/', 'post',
          {
            macAddress: mac
          }
        ).then(async (response) => {
          // set interval of 5 seconds
          setTimeout(async () => {
            const result = response.data;

            const promises = Object.entries(result).map(async ([key, value]) => {
              if (value !== "Device not found") {
                const taskResult = await this.getTask(value);
                return {
                  mac: key,
                  result: taskResult ? true : false,
                };
              }
            });
          
          const list = (await Promise.all(promises))
          resolve(list);
          }, 5000)

          
        }).catch((error) => {
          reject(error);
        });
      })
    },
    async getTask(task_id) {
      return new Promise((resolve, reject) => {
        APIRequest('gadgets/device/test_result/', 'post',
          {
            task_id: task_id
          }
        ).then((response) => {
          if(response.data === "TaskReport with given task_id doesn't exist") resolve('')
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      })
    },
    async getFolders() {
      return new Promise((resolve, reject) => {
        APIRequest(`gadgets/group/?source_ref=${appStore().getCurrentTenant}`)
          .then((response) => {
            resolve(response.data['results']);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getPlayersAPI() {
      return new Promise((resolve, reject) => {
        APIRequest(`/gadgets/device/device_list/${appStore().getCurrentTenant}`)
          .then((response) => {
            this.players = response.data;
            resolve(this.players);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getAvailablePlayersAPI(search, page, pageSize, sort) {
      if (this.isLoading) this.controller.abort();

      this.controller = new AbortController();
      this.isLoading = true;

      await APIRequest(
          `/gadgets/device/getAvailableDevices/?page=${page}&page_size=${pageSize}`,
          'post',
          {
            ncm: String(appStore().getCurrentTenant),
            contains: search,
            ordering: sort ? sort : 'id',
          },
          { signal: this.controller.signal },
        )
        .then((response) => {
          if(!response?.data) return;
          this.count = response.data['count'];
          this.next = response.data['next'];
          this.previous = response.data['previous'];
          this.availablePlayers = response.data['results'];
          this.totalPages = this.calculateTotalPages(this.count, pageSize);
          this.error = '';
          if (this.playersFilterList.length == 0) {
            this.playersFilterList = this.availablePlayers.map((player) => {
              return { value: player.location.name, name: player.location.name };
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.name !== 'CancelledError') {
            this.error = error;
            this.isLoading = false;
          }
        });
    },
    async getDevices(page, pageSize, filter, models, sort, search) {
        if (this.isLoading) {
            this.controller.abort();
        }

        this.controller = new AbortController();
        this.isLoading = true;

        await APIRequest(
            `gadgets/device/device_list/?page=${page}&page_size=${pageSize}&search=${search}`, 'post', {
                tenant_list: !filter.length ? appStore().getListedHotels : filter,
                models: models.length ? models : [],
                ordering: sort ? sort : 'id',
            },
            {
                signal: this.controller.signal
            }
        )
            .then((response) => {
                    if(!response?.data) return;
                    this.count = response.data["count"];
                    this.next = response.data["next"];
                    this.previous = response.data["previous"];
                    this.devices = response.data["results"];
                    this.totalPages = this.calculateTotalPages(this.count, pageSize)
                    this.error = '';
                    this.isLoading = false;

                }
            ).catch((error) => {
                if (error.name !== 'CanceledError') {
                    this.error = error;
                    console.error(error);
                    this.isLoading = false;
                }
            });

    },
    async rebootPlayer(player) {
      await APIRequest('/gadgets/device/reboot/', 'post', {
          macAddresses: [player],
          schedule: 'now'
        })
        .then((res) => {return res.data})
        .catch((err) => {return err});
    },
    async screenshotPlayer(player) {
      await APIRequest('/gadgets/device/screenshot/', 'post', {
        macAddresses: [player],
        schedule: 'now'
      })
      .then((res) => {return res.data})
      .catch((err) => {return err});
  },
    async connectivityTest(player) {
      return new Promise((resolve, reject) => {
        APIRequest('/gadgets/device/connectivity_test/', 'post', {
          macAddresses: [player],
          schedule: 'now'
        })
        .then((res) => { 
           resolve(res.data) 
          })
        .catch((err) => reject(err));
      })
  },
    async getPublishPlayersAPI(orientation, type) {
      return new Promise((resolve, reject) => {
        const playerType = type === 'conference_room' ? 'conference' : type;
        APIRequest(
            `/gadgets/device/?location__tenant=${
              appStore().getCurrentTenant
            }&orientation=${orientation}&location__type=${playerType}`,
          )
          .then((response) => {
            resolve(response.data.results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getPlayersByPresentationId(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`/gadgets/device/?presentation__id=${id}`)
          .then((response) => {
            resolve(response.data.results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async renameGroup(id, groupName) {
      return new Promise((resolve, reject) => {
        const url = `gadgets/group/${id}/`;
        APIRequest(url, 'patch', {
            friendly_name: groupName,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async addGroup(folder_name) {
      return new Promise((resolve, reject) => {
        const url = `gadgets/group/`;
        APIRequest(url, 'post', {
            friendly_name: folder_name,
            source_ref: appStore().getCurrentTenant,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deleteGroup(id) {
      return new Promise((resolve, reject) => {
        const url = `gadgets/group/${id}/`;
        APIRequest(url, 'delete')
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async changeTemplateAPI(template_id, players) {
      await players.forEach((player) => {
        APIRequest(`gadgets/device/${player.id}/`, 'patch', {
          presentation: template_id,
        });
      });
    },
    async addPlayerTests(device_id, test){
      return new Promise((resolve, reject) => {
        const urlDevice = `gadgets/device/${device_id}/`;
        APIRequest(urlDevice, 'patch', {
            test_results: test,
            last_test_issued: dayjs().format(
              "YYYY-MM-DD HH:mm:ss",
            ),
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })

    },
    async addPlayer(form) {
      return new Promise((resolve, reject) => {
        const urlDevice = `gadgets/device/`;

        APIRequest(urlDevice, 'post', {
            location: {
              name: form.name,
              type: form.type,
              external_code: null,
              tenant: appStore().getCurrentTenant,
            },
            flip: form.flip,
            orientation: form.orientation,
            macAddress: form.macAddress,
            ipAddress: '10.0.0.101',
            model: 'STB 280A',
            version: '9.7.2',
            status: true,
            presentation: '',
            group: form.group,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async editPlayer(form, id) {
      return new Promise((resolve, reject) => {
        const urlDevice = `gadgets/device/${id}/`;

        APIRequest(urlDevice, 'patch',{
            location: {
              id: form.location.id,
              name: form.name,
              type: form.type,
              external_code: null,
            },
            flip: form.flip,
            orientation: form.orientation,
            macAddress: form.macAddress,
            group: form.group,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deletePlayer(id) {
      return new Promise((resolve, reject) => {
        const urlDevice = `gadgets/device/${id}/`;

        APIRequest(urlDevice, 'delete')
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    calculateTotalPages(totalItems, itemsPerPage) {
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      return totalPages < 1 ? 1 : totalPages;
    },

    // Management View
    getFirmware(sort){
      return new Promise((resolve, reject) => {
        APIRequest(`gadgets/device_firmware/${sort ? `?ordering=${sort}` : ''}`)
          .then((response) => {
            resolve(response.data['results']);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFirmware(id){
      return new Promise((resolve, reject) => {
        APIRequest(`gadgets/device_firmware/${id}/`, 'delete')
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async uploadFileToS3(url, file){
      try {
        await axios.put(url, file, {
           headers: {
             "Content-Type": file.type, // Ensure the Content-Type matches the file type
           },
           withCredentials: false, // Ensure this is false for CORS to work correctly
         });
        } catch (error) {
          console.error('Upload error:', error.response ? error.response.data : error.message);
        }
    },
    async uploadFirmwareS3(file) {
      try {
        const generated = await APIRequest('gadgets/device_firmware/generate_firmware_presigned_urls/', 'post', 
        {
          "name": file.name,
        })
          
        await this.uploadFileToS3(generated.data.firmware_url, file)


        let formData = new FormData();
        formData.append("file_key", generated.data.firmware_key);
        formData.append("name", file.name);

        await this.uploadFirmware(formData);
        return true;

      } catch (error) {
        console.log(error);
        return false;
      }
  },
    uploadFirmware(formData){
      return new Promise((resolve, reject) => {
        const url = `gadgets/device_firmware/create_firmware/`;
        APIRequest(url,'post', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    


    // UPGRADES
    async getSchedulingLogs(){
      return new Promise((resolve, reject) => {
        const url = `timeline/scheduled-upgrade/get_logs/`;
        APIRequest(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    async createScheduling(title, devices, date, firmwares){
      return new Promise((resolve, reject) => {
        const url = `timeline/scheduled-upgrade/create_scheduling/`;
        APIRequest(url,'post', {
            title,
            devices,
            date,
            firmwares
          })
          .then(() => {
            // Resolve the Promise with the response data
            resolve(true);
          })
          .catch(() => {
            // Reject the Promise with the error
            reject(false);
          });
      });
    },
    async editScheduling(id, title, devices, date, firmwares){
      return new Promise((resolve, reject) => {
        const url = `timeline/scheduled-upgrade/edit_scheduling/${id}`;
        APIRequest(url,'post', {
            title,
            devices,
            date,
            firmwares
          })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    async upgradeNow(id, devices){
      return new Promise((resolve, reject) => {
        const url = `timeline/scheduled-upgrade/edit_scheduling/${id}`;
        APIRequest(url,'post', {
            devices,
            date: 'now'
          })
          .then(() => {
            // Resolve the Promise with the response data
            resolve(true);
          })
          .catch(() => {
            // Reject the Promise with the error
            reject(false);
          });
      });
    },
    async deleteScheduling(id){
      return new Promise((resolve, reject) => {
        const url = `timeline/scheduled-upgrade/${id}/`;
        APIRequest(url, 'delete')
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    async getScheduledUpgrades(){
      return new Promise((resolve, reject) => {
        APIRequest(`timeline/scheduled-upgrade/`, 'get')
          .then((response) => {
            resolve(response.data['results']);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getAllModels(){
      return new Promise((resolve, reject) => {
        APIRequest(`gadgets/device_model/`, 'get')
          .then((response) => {
            resolve(response.data['results']);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getAllDevices(){
      return new Promise((resolve, reject) => {
        
        APIRequest(
          `gadgets/device/device_list_raw/`, 'post'
          )
          .then((response) => {
              resolve(response.data);
          }).catch((error) => {
              reject(error);
          });


        
      });
    }
  },
});
