import {defineStore} from "pinia";
import {useToast} from "vue-toastification";
import {useAppStore as appStore} from '@/store'

import dayjs from "dayjs";
import APIRequest from "@/libs/cloud_auth";

export const useRoomStore = defineStore("room", {
  state: () => ({
    isLoading: false,
    dragging: false,
    selectedDate: new dayjs(),
    selectedState: [],
    options: [],
    selectedRoom: [],
    updateRooms: false,
    rooms: [],
    rooms_new: [],
  }),
  getters: {
    getUpcomingEvents() {
      let events = [];

      for (let x = 0; x < this.rooms.length; x++) {
        for (let i = 0; i < this.rooms[x].meetings.length; i++) {
          let startDate = dayjs(this.rooms[x].meetings[i].start_time);
          let endDate = dayjs(this.rooms[x].meetings[i].end_time);

            if (
              !dayjs().isAfter(endDate, "millisecond") &&
            dayjs().isBefore(startDate, "millisecond")
            ) {
              events.push(this.rooms[x].meetings[i]);
            }
          
        }
      }
      return events.sort((a, b) => a.startDate - b.startDate);
    },
    getOngoingEvents() {
      let events = [];
      
      for (let x = 0; x < this.rooms.length; x++) {
        for (let i = 0; i < this.rooms[x].meetings.length; i++) {
          let startDate = dayjs(this.rooms[x].meetings[i].start_time);
          let endDate = dayjs(this.rooms[x].meetings[i].end_time);

            if (
              !dayjs().isAfter(endDate, "millisecond") &&
              dayjs().isBetween(startDate, endDate, "millisecond", "[]")
            ) {
              events.push(this.rooms[x].meetings[i]);
            }
          
        }
      }
      return events.sort((a, b) => a.startDate - b.startDate);
    },
    getRoomOptions(){
      let rooms = this.options.map((el) => {return {name: el.name, value: el.id}})
      return rooms
    }
  },
  actions: {
    rgbToHex(color){
      if (color.startsWith("#")) {
        return color; // Input is already a hex value, return it as is
      }

      let values = color.match(/\d+/g); // Extract numeric values from the string
      let r = parseInt(values[0].trim());
      let g = parseInt(values[1].trim());
      let b = parseInt(values[2].trim());
      return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
    },
    async getScheduler() {
      try {
        let response = await APIRequest(
          `timeline/scheduler-list/?date_value=${dayjs(
            this.selectedDate
          ).format("YYYY-MM-DD")}&source_ref_id=${appStore().getCurrentTenant}`
        );
        this.rooms = response.data
        return response.data;
      } catch (error) {
        useToast().error(error.message);
      }
    },
    async getLocations() {
      try {
        let response = await APIRequest(
          `gadgets/location/?type=conference&ordering=name&tenant=${appStore().getCurrentTenant}`
        ).catch(e => console.error(e))
        if (response.status === 200) return response.data;
      } catch (error) {
        useToast().error(error.message);
      }
    },
    async getAllLocations() {
      try {
        let response = await APIRequest(
          `gadgets/location/?ordering=name&tenant=${appStore().getCurrentTenant}`
        ).catch(e => console.error(e))
        if (response.status === 200) return response.data;
      } catch (error) {
        useToast().error(error.message);
      }
    },
    async addEvent(form) {

      return new Promise((resolve, reject) => {
        APIRequest(`timeline/scheduler/`, 'post', {
            scheduled_contents: [],
            conference_meetings: [
              {
                color_code: this.rgbToHex(form.color),
                location: form.room,
                logo_asset: form.logo,
              },
            ],
            name: form.title,
            description: form.description,
            type: "conference",
            source_ref_type: 'tenant',
            source_ref_id: appStore().getCurrentTenant,
            start_time: dayjs(form.range.start).format('YYYY-MM-DD HH:mm'),
            end_time:  dayjs(form.range.end).format('YYYY-MM-DD HH:mm'),
            is_recurrent: false,
            recurrency_pattern: null,
          })
          .then((response) => {
            // Resolve the Promise with the response data
            useToast().success("Event added successfully!");
            resolve(response.data);
          })
          .catch((error) => {
            // Reject the Promise with the error
            useToast().error("Error adding event!");
            reject(error);
          });
        });
      },
    async editEvent(form, room) {
      {
        return new Promise((resolve, reject) => {
          APIRequest(`timeline/scheduler/${room.id}/`, 'patch', {
              id: room.id,
              scheduled_contents: [],
              conference_meetings: [
                {
                  id: room.conference_id,
                  scheduler: room.id,
                  color_code: this.rgbToHex(form.color),
                  location: form.room,
                  logo_asset: form.logo,
                },
              ],
              name: form.title,
              description: form.description,
              start_time: dayjs(form.range.start).format('YYYY-MM-DD HH:mm'),
              end_time:  dayjs(form.range.end).format('YYYY-MM-DD HH:mm'),
              is_recurrent: room.is_recurrent,
              recurrency_pattern: room.recurrency_pattern,
            })
            .then((response) => {
              // Resolve the Promise with the response data
              useToast().success("Event edited successfully!");
              resolve(response.data);
            })
            .catch((error) => {
              // Reject the Promise with the error
              useToast().error("Error editing event!");
              reject(error);
            });
        });
      }
    },
    async removeEvent(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`timeline/scheduler/${id}/`, 'delete')
          .then((response) => {
            // Resolve the Promise with the response data
            useToast().success("Event deleted");
            resolve(response.data);
          })
          .catch((error) => {
            // Reject the Promise with the error
            useToast().error("Error deleting event!");
            reject(error);
          });
      });
    },
    async dragEvent(roomId, event) {
      return new Promise((resolve, reject) => {
        APIRequest(`timeline/scheduler/${event.id}/conference-meeting/${event.conference_id}/`, 'put', {
                location: roomId,
              })
          .then((response) => {
            // Resolve the Promise with the response data           
            resolve(response.data);
          })
          .catch((error) => {
            // Reject the Promise with the error
            useToast().error("Error moving event!");
            reject(error);
          });
      });

    },
  },
});
